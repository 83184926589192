export enum FilterType {
  CLIENT_NAME = 'client_name',
  TITLE = 'title',
  LABEL = 'label',
  INDUSTRY = 'industry',
  REGION = 'region',
  CAPABILITY = 'capability',
  DATE_RANGE = 'date_range',
  NAME = 'name',
};

interface IFilterObjConst {
  label: string;
  operator: string;
  keyword?: string;
}

export const FilterObj: Record<string, IFilterObjConst> = {
  [FilterType.CLIENT_NAME]: {
    label: 'Client Name',
    operator: 'contains',
    keyword: 'project.client_company.company_name'
  },
  [FilterType.TITLE]: {
    label: 'Position Title',
    operator: 'contains',
    keyword: 'project.title'
  },
  [FilterType.LABEL]: {
    label: 'Project Label',
    operator: 'contains',
    keyword: 'project.label'
  },
  [FilterType.INDUSTRY]: {
    label: 'Industry',
    operator: 'contains',
    keyword: 'project.client_company.industry'
  },
  [FilterType.REGION]: {
    label: 'Region',
    operator: 'contains',
    keyword: 'project.location_raw'
  },
  // [FilterType.CAPABILITY]: {
  //   label: 'Capability',
  //   operator: 'eq',
  //   keyword: 'project.capability'
  // },
  [FilterType.DATE_RANGE]: {
    label: 'Date Range',
    operator: 'lte',
  },
  [FilterType.NAME]: {
    label: 'Project Name', // Credential Name
    operator: 'eq',
  }
};