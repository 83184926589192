import {
  createApi,
  fetchBaseQuery
} from "@reduxjs/toolkit/query/react";
// util
import { matchesUrl } from "findem-helpers";
// types
import {
  ICreateCredentialPayload,
  ILinkBeaconToCredPayload,
  IUnlinkBeaconToCredPayload,
  ICredByRefPayload,
  ISearchCredsPayload,
  ICredHistoryPayload,
  ISearchAssignmentsPayload,
  ICredentialResponse,
  ICredentialsResponse,
  IUpdateCredentialPayload,
  IRequirement,
  IGetAssignmentsPayload,
  ICredBuilderFiltersACPayload,
  ICredBuilderFiltersACResp,
  IDeleteCredentialResponsse,
  IDeleteCredentialPayload,
  ICredBuilderImgUploadResponse,
  ICredBuilderImgUploadPayload,
  IGetLinkedSearchDetailsResponse,
  IGetLinkedSearchDetailsPayload
} from "../types/credential";
import { IProjectsResponse } from "../types/project";
import { IATSJob, IGetAtsJobsPayload } from "../types/ats";
import { buildStateFromCredByRef } from "../reducers/presentationSlice";

const DEFAULT_AC_LIMIT = "40";

export const credentialMatchesApi = createApi({
  reducerPath: "credentialMatches",
  tagTypes: ['Credentials', 'CredentialByRef'],
  baseQuery: fetchBaseQuery({
    baseUrl: matchesUrl
  }),
  endpoints: (build) => ({
    createCred: build.mutation<ICredentialResponse, ICreateCredentialPayload>({
      query: ({ name, project_id, joid }) => {
        const params = new URLSearchParams({ joid });

        return {
          url: `/hm/api/credbuilder?${params.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: {
            'ref': undefined,
            'name': name,
            ...(project_id && { project: { project_id } })
          }
        }
      }
    }),
    updateCred: build.mutation<ICredentialResponse, IUpdateCredentialPayload>({
      query: ({ joid, credential }) => {
        const params = new URLSearchParams({ joid });

        return {
          url: `/hm/api/credbuilder?${params.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: credential
        }
      }
    }),
    deleteCred: build.mutation<IDeleteCredentialResponsse, IDeleteCredentialPayload>({
      invalidatesTags: ['Credentials'],
      query: ({ ref }) => {

        return {
          url: `/hm/api/credbuilder`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'DELETE',
          body: {
            ref
          }
        }
      }
    }),
    linkBeaconToCred: build.mutation<ICredentialResponse, ILinkBeaconToCredPayload>({
      query: ({ ref, project_id, joid }) => {
        const params = new URLSearchParams({ joid });

        return {
          url: `/hm/api/credbuilder?${params.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: {
            ref,
            project: { project_id }
          }
        }
      }
    }),
    unlinkBeaconToCred: build.mutation<ICredentialResponse, IUnlinkBeaconToCredPayload>({
      query: ({ ref, joid }) => {
        const params = new URLSearchParams({ joid });

        return {
          url: `/hm/api/credbuilder?${params.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: {
            ref
          }
        }
      }
    }),
    credByRef: build.query<ICredentialResponse, ICredByRefPayload>({
      providesTags: (result) => result ? [{ type: 'CredentialByRef', ref: result.data.ref }] : [],
      query: ({ ref, joid }) => {
        const params = new URLSearchParams({ ref, joid });

        return {
          url: `/hm/api/credbuilder?${params.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: {
            ref
          }
        }
      },
      transformResponse: (response: ICredentialResponse) => {
        response.data.requirements = response.data.requirements.map(
          (requirement: IRequirement) => {
            requirement.bool_str = unescape(requirement.bool_str);
            if (requirement.req_cxt?.bool_cxt) {
              requirement.req_cxt.bool_cxt = requirement.req_cxt.bool_cxt.map((bool: string) => unescape(bool));
            }
            return requirement;
          }
        );
        return response;
      },
      onCacheEntryAdded: async (arg, { cacheDataLoaded, cacheEntryRemoved, updateCachedData, dispatch }) => {
        try {
          const cacheData = await cacheDataLoaded;
          // this initialises the presentation slice
          dispatch(buildStateFromCredByRef(cacheData.data.data));
        } catch (error) {
          console.error("Error in onCacheEntryAdded of credByRef ", error);
        }
      }
    }),
    searchCreds: build.query<ICredentialsResponse, ISearchCredsPayload>({
      providesTags: (result) => result ? [{ type: 'Credentials' }] : [],
      query: ({ page, size, filters, joid }) => {
        const params = new URLSearchParams({ joid });

        return {
          url: `/hm/api/credbuilder/search?${params.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: {
            page,
            size,
            ...(filters && { filters })
          }
        }
      }
    }),
    credHistory: build.query<ICredentialsResponse, ICredHistoryPayload>({
      query: ({ ref, joid }) => {
        const params = new URLSearchParams({ joid });

        return {
          url: `/hm/api/credbuilder/history?${params.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: {
            ref
          }
        }
      }
    }),
    searchAssignments: build.query<IProjectsResponse, ISearchAssignmentsPayload>({
      query: ({ page, size, project_ref, refs, requirements, shortlist, joid, search_id }) => {
        const params = new URLSearchParams({ joid });

        return {
          url: `/hm/api/assignment/search?${params.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: {
            page,
            size,
            ...(search_id && { search_id }),
            ...(project_ref && { project_ref }),
            ...(requirements && { requirements }),
            ...(shortlist && { shortlist }),
            ...(refs && { refs })
          }
        }
      }
    }),
    getAssignments: build.query<IProjectsResponse, IGetAssignmentsPayload>({
      query: ({ page, size, refs, joid, requirements }) => {
        const params = new URLSearchParams({ joid });

        return {
          url: `/hm/api/assignment/search?${params.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: {
            page,
            size,
            requirements,
            ...(refs && { refs })
          }
        }
      },
      transformResponse: (response: IProjectsResponse, meta, arg) => {
        // make the order of the refs in the response the same as the refs passed in the payload
        // BE doesnt follow the order of the refs passed in the payload in the response data
        const sortedData = response.data.sort((a, b) => {
          return arg.refs.indexOf(a.ref) - arg.refs.indexOf(b.ref);
        });

        return { ...response, data: sortedData };
      }
    }),
    getAtsJobs: build.query<IATSJob[], IGetAtsJobsPayload>({
      query: ({ joid }) => {
        const params = new URLSearchParams({ joid, is_rra: "true" });

        return {
          url: `/hm/api/ats-jobs?${params.toString()}`,
          credentials: 'include'
        }
      },
    }),
    getCredBuilderFiltersAC: build.query<ICredBuilderFiltersACResp, ICredBuilderFiltersACPayload>({
      query: (params: ICredBuilderFiltersACPayload) => {
        const searchParams = new URLSearchParams({
          field: params.field,
          prefix: params.prefix,
          joid: params.joid,
          limit: DEFAULT_AC_LIMIT
        });

        return {
          url: `/hm/api/credbuilder/autocomplete?${searchParams.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'GET'
        }
      }
    }),
    getAssignmentsFieldsAC: build.query<ICredBuilderFiltersACResp, ICredBuilderFiltersACPayload>({
      query: (params: ICredBuilderFiltersACPayload) => {
        const searchParams = new URLSearchParams({
          field: params.field,
          prefix: params.prefix,
          joid: params.joid,
          limit: DEFAULT_AC_LIMIT
        });

        return {
          url: `/hm/api/assignment/autocomplete?${searchParams.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'GET'
        }
      }
    }),
    uploadImg: build.mutation<ICredBuilderImgUploadResponse, ICredBuilderImgUploadPayload>({
      query: ({ joid, formData }) => {
        const searchParams = new URLSearchParams({ joid });

        return {
          url: `/hm/api/credbuilder/img_upload?${searchParams.toString()}`,
          credentials: 'include',
          method: 'POST',
          body: formData
        }
      }
    }),
    getLinkedSearchDetails: build.query<IGetLinkedSearchDetailsResponse, IGetLinkedSearchDetailsPayload>({
      query: (params: IGetLinkedSearchDetailsPayload) => {
        const searchParams = new URLSearchParams({
          joid: params.joid,
          refs: params.refs.join(",")
        });

        return {
          url: `/hm/api/assignment/icp?${searchParams.toString()}`,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'GET'
        }
      }
    })
  })
});

// selectors
export const selectAddRemoveAssignmentMutationStatus = credentialMatchesApi.endpoints.updateCred.select('add-remove-assginment');

export const {
  endpoints,
  useCreateCredMutation,
  useUpdateCredMutation,
  useDeleteCredMutation,
  useLinkBeaconToCredMutation,
  useUnlinkBeaconToCredMutation,
  useCredByRefQuery,
  useSearchCredsQuery,
  useCredHistoryQuery,
  useSearchAssignmentsQuery,
  useLazySearchAssignmentsQuery,
  useGetAssignmentsQuery,
  useGetAtsJobsQuery,
  useLazyGetCredBuilderFiltersACQuery,
  useLazyGetAssignmentsFieldsACQuery,
  useUploadImgMutation,
  useGetLinkedSearchDetailsQuery
} = credentialMatchesApi;